import React from 'react';

const defaultState = { dark: false };

const ThemeContext = React.createContext(defaultState);

class ThemeProvider extends React.Component {
	
	state = {
		dark: false,
	};

	componentDidMount() {
		if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
			this.setState({ dark: true });
		}
	}

	render() {
		const { children } = this.props;
		const { dark } = this.state;
		return (
			<ThemeContext.Provider value={{ dark }}>{children}</ThemeContext.Provider>
		);
	}
}

export default ThemeContext;
export { ThemeProvider };
